module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-883aeba424/0/cache/gatsby-plugin-google-gtag-npm-5.13.1-993d935611-70a88d4abd.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-113547112-1","G-VHL3G6QG0S","AW-781608096"],"pluginConfig":{"head":false,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../.yarn/cache/gatsby-plugin-facebook-pixel-npm-1.0.8-5aef36b44a-3037256b8c.zip/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"456327274863301"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-e1b4357fb9/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
